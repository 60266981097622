<template>
  <div class="shortcut-box">
    <div class="group-operate">
      <Button type="primary" size="default" class=" ml10" @click="handAll('handAll','群调')"><img class="opsIcon" :src="img.visual.tunnel.GroupTuning" alt=""> 群调</Button>
      <Button type="primary" size="default" class=" ml10" @click="handAll('handAll1','群开')"><img class="opsIcon" :src="img.visual.tunnel.opsOpen" alt="">群开</Button>
      <Button type="primary" size="default" class=" ml10" @click="handAll('handAll0','群关')"><img class="opsIcon" :src="img.visual.tunnel.opsClose" alt="">群关</Button>

      <Button type="primary" size="default" class=" ml10" @click="handGroup('handGroup1','组调')"><img class="opsIcon" :src="img.visual.tunnel.GroupTuning" alt="">组调</Button>
      <Button type="primary" size="default" class=" ml10" @click="handGroup('handGroup11','组开')"><img class="opsIcon" :src="img.visual.tunnel.opsOpen" alt="">组开</Button>
      <Button type="primary" size="default" class=" ml10" @click="handGroup('handGroup10','组关')"><img class="opsIcon" :src="img.visual.tunnel.opsClose" alt="">组关</Button>

    </div>
    <div class="shortcut-parameter">
      <div class="parameter-box">
        <div class="parameter-title">
          透传通道
        </div>
        <div class="channel-list">
          <a href="javascript:void(0);" :class="[form.channel==1?'channel-active':'','channel-item']" @click="channelBut(1)">通道一</a>
          <a href="javascript:void(0);" :class="[form.channel==2?'channel-active':'','channel-item']" @click="channelBut(2)">通道二</a>
          <a href="javascript:void(0);" :class="[form.channel==3?'channel-active':'','channel-item']" @click="channelBut(3)">通道三</a>
          <a href="javascript:void(0);" :class="[form.channel==4?'channel-active':'','channel-item']" @click="channelBut(4)">通道四</a>
          <a href="javascript:void(0);" :class="[form.channel==5?'channel-active':'','channel-item']" @click="channelBut(5)">通道五</a>
          <a href="javascript:void(0);" :class="[form.channel==6?'channel-active':'','channel-item']" @click="channelBut(6)">通道六</a>
        </div>
      </div>
      <div class="parameter-box">
        <div class="parameter-title">
          延时时间
        </div>
        <div class="datetime">
          <!-- <DatePicker type="datetime" v-model="form.expire" style="width: 320px;height:40px;" format="yyyy-MM-dd HH:mm:ss" :key="delayedMin"></DatePicker> -->
          <div>时间选择：</div>
          <div><vxe-input v-model="form.expire" placeholder="日期和时间选择" type="datetime" transfer class="datetime-inp"></vxe-input></div>
        </div>
        <div class="datetime">
          <div>分钟选择：</div>
          <div>
            <Slider v-model="delayedMin" show-input input-size='small' :max="1440" class="datetime-slider"></Slider>
          </div>
        </div>
      </div>
      <div class="parameter-box">
        <div class="parameter-title">
          调光
        </div>
        <div class="parameter-dimming">
          <div class="dimming-form">
            <div class="bright-list">
              <span>亮度1：</span>
              <div class="bright-box">
                <Slider v-model="form.bright1" show-input input-size='small'></Slider>
              </div>
            </div>
            <div class="bright-list">
              <span>亮度2：</span>
              <div class="bright-box">
                <Slider v-model="form.bright2" show-input input-size='small'></Slider>
              </div>
            </div>
          </div>
          <div class="dimming-form">
            <div class="bright-list">
              <span>色温1：</span>
              <div class="bright-box">
                <Slider v-model="form.color1" show-input input-size='small'></Slider>
              </div>
            </div>
            <div class="bright-list">
              <span>色温2：</span>
              <div class="bright-box">
                <Slider v-model="form.color2" show-input input-size='small'></Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="parameter-channel"></div>
      <div class="parameter-expire"></div>
      <div class="parameter-dimming"></div> -->
    </div>
    <div class="group-control">
      <div class="group-control-title">
        <div class="parameter-title">
          组控操作
        </div>
        <div class="group-control-but">
        </div>
      </div>
      <div class="group-control-list mt10">
        <div class="group-control-list-box" v-for="el,index in lampGroups" :key="index">
          <Checkbox v-model="el.checkbox">
            <span class="list-name">
              分组名称:{{el.name}}
            </span>
          </Checkbox>
          <div class="list-box-con"><span>灯控总数:</span> <i>{{el.count}}</i></div>
          <div class="list-box-con"><span>灯控在线数:</span> <i>{{el.count}}</i></div>
          <div class="list-box-con"><span>灯控运行数:</span> <i>{{el.running}}</i></div>
          <div class="list-box-con"><span>灯控报警数:</span> <i>{{el.alarm}}</i></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Header',
  components: {
  },
  data() {
    return {
      lampGroups: [],
      showEditModal: false,
      delayedMin: 60,
      form: {
        "expire": '', //控制时长
        "bright1": 20,
        "bright2": 40,
        "color1": 50,
        "color2": 50,
        "channel": 6, //组控下行通道
      },
    }
  },
  created: function () {
  },

  computed: {
  },
  watch: {
    'form.expire'() {
      let d = new Date(this.form.expire);
      this.delayedMin = ((d.getTime() - new Date().getTime()) / 1000 / 60).toFixed(0)*1;
    },
    delayedMin() {
      let date = new Date();
      this.form.expire = new Date(date.getTime() + this.delayedMin * 60000);
    }
  },
  mounted: function () {
    this.init();
    let date = new Date();
    this.form.expire = new Date(date.getTime() + 60 * 60000);
  },
  destroyed: function () {
  },
  methods: {
    channelBut(num) {
      this.form.channel = num;
    },
    handAll(code, name) {
      this.$Modal.confirm({
        title: name,
        content: '<p>确定进行' + name + '操作吗？</p>',
        okText: '发送',
        onOk: () => {
          let d = new Date(this.form.expire);
          let expire = ((d.getTime() - new Date().getTime()) / 1000 / 60).toFixed(0);
          let ajax = {
            "code": code,
            "expire": expire * 1,
            "bright1": this.form.bright1,
            "bright2": this.form.bright2,
            "color1": this.form.color1,
            "color2": this.form.color2,
            "channel": this.form.channel,
          }
          this.$axios.post(`device/light/HandAll`, ajax).then(res => {
            if (res.code == 0) {
              this.$Message.warning('发送成功');
            } else {
              this.$Message.warning(res.data);
            }
          });
        },
        onCancel: () => {

        }
      });

    },
    handGroup(code, name) {
      let arr = this.lampGroups.filter(p => p.checkbox);
      if (arr.length == 0) {
        this.$Message.warning('请选择分组进行操作');
        return false;
      }
      this.$Modal.confirm({
        title: name,
        content: '<p>确定进行' + name + '操作吗？</p>',
        okText: '发送',
        onOk: () => {
          let d = new Date(this.form.expire);
          let expire = ((d.getTime() - new Date().getTime()) / 1000 / 60).toFixed(0);
          let ajax = {
            "groups": [], //分组ID列表
            "code": code,
            "expire": expire * 1,
            "bright1": this.form.bright1,
            "bright2": this.form.bright2,
            "color1": this.form.color1,
            "color2": this.form.color2,
            "channel": this.form.channel,
          }

          arr.forEach(el => {
            ajax.groups.push(el.id)
          });
          this.$axios.post(`device/light/HandGroup`, ajax).then(res => {
            if (res.code == 0) {
              this.$Message.warning('发送成功');
            } else {
              this.$Message.warning(res.data);
            }
          });
        },
        onCancel: () => {

        }
      });

    },
    init() {
      this.$axios.post(`device/light/QueryLampGroups`, {}).then(res => {
        if (res.code == 0) {
          res.data.forEach(el => {
            el.checkbox = false;
          });
          this.$set(this, 'lampGroups', res.data);
        }
      });
    },
  },
}
</script>

<style scoped>
.shortcut-box {
  background: #f6f6fa;
  height: 100%;
  overflow-y: auto;
  padding: 20px 24px;
}
.group-operate {
  text-align: right;
  padding: 0 24px;
}
.shortcut-parameter {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.parameter-box {
  width: calc((100% - 48px) / 3);
  height: 168px;
  background: #ffffff;
  border-radius: 8px;
  padding: 22px;
}
.parameter-title {
  height: 20px;
  font-weight: 500;
  font-size: 20px;
  color: #333333;
  border-left: 3px solid #3379fb;
  line-height: 20px;
  text-indent: 9px;
}
.channel-list {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}
.channel-item {
  width: 74px;
  height: 32px;
  background: #e8edf9;
  border-radius: 4px;
  font-size: 16px;
  color: #333333;
  line-height: 32px;
  text-align: center;
  display: inline-block;
}
.channel-active {
  color: #ffffff;
  background: #3379fb;
}
.datetime {
  margin-top: 20px;
  text-align: center;
  display: flex;
  line-height: 34px;
}
.datetime-inp {
  width: 400px;
}
.datetime-slider {
  width: 415px;
}
/* .handGroupList {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
} */
.parameter-dimming {
  margin-top: 10px;
  display: flex;
}
.dimming-form {
  width: 50%;
  display: flex;
  flex-direction: column;
}
.bright-list {
  display: flex;
  line-height: 36px;
  margin-top: 10px;
}
.bright-box {
  width: 175px;
}
>>> .ivu-input-number {
  width: 55px;
  margin-right: 15px;
}
.group-control-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.group-control {
  margin-top: 20px;
  background-color: #fff;
  padding: 24px;
}
.group-control-list-box {
  width: 300px;
  height: 230px;
  background: #f8faff;
  border-radius: 10px;
  border: 1px solid #f8faff;
  margin-top: 24px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.list-name {
  height: 18px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 18px;
  color: #333333;
}
>>> .ivu-checkbox-inner {
  width: 18px;
  height: 18px;
  top: -2px;
}
>>> .ivu-checkbox-checked .ivu-checkbox-inner:after {
  width: 6px;
}
.list-box-con {
  display: flex;
  justify-content: space-between;
}
.list-box-con span {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 15px;
  color: #666666;
}
.list-box-con i {
  display: inline-block;
  padding: 0 13px;
  background-color: #fff;
  border: 1px solid #9fa8b7;
  border-radius: 4px;
  font-style: normal;
}
.group-control-but {
  text-align: right;
}
.opsIcon {
  width: 14px;
  height: 14px;
  margin: 0 10px 2px 0;
  vertical-align: middle;
}
</style>